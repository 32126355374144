.rating-pills {
  height: 1.5rem;
  font-size: 12px;
  padding: 0.2rem 2rem;
  margin: 5px 15px 5px 0;
}

.rating-star {
  font-size: 15px;
  padding: 0.2rem;
}

.score {
  padding: 0 0.6rem;
}

.col-rating {
  padding: 0;

  .rating-container {
    align-items: flex-end;
  }
}

.rating-count-popover {
  width: 300px;
  background-color: white;
  box-shadow: $box-shadow;
  border: 1px solid $border-color;
}

.rating-count-wrapper {
  .rating-count-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .score-col,
  .count-col {
    text-align: end;
  }

  .score-col {
    width: 40px;
  }

  .count-col {
    width: 20px;
  }

  .progress-col {
    width: 160px;
  }
}

#search-in-progress {
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    display: inline-block;
    margin: 0 20px;
  }

  #preloader {
    position: static;

    #status {
      position: static;
      width: 20px;
      height: 20px;
      margin: 0;

      .spinner-chase {
        width: 20px;
        height: 20px;
      }
    }
  }
}